exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-dinner-menu-tsx": () => import("./../../../src/pages/dinner-menu.tsx" /* webpackChunkName: "component---src-pages-dinner-menu-tsx" */),
  "component---src-pages-find-us-tsx": () => import("./../../../src/pages/find-us.tsx" /* webpackChunkName: "component---src-pages-find-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lunch-menu-tsx": () => import("./../../../src/pages/lunch-menu.tsx" /* webpackChunkName: "component---src-pages-lunch-menu-tsx" */),
  "component---src-pages-menu-select-tsx": () => import("./../../../src/pages/menu-select.tsx" /* webpackChunkName: "component---src-pages-menu-select-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-regular-menu-tsx": () => import("./../../../src/pages/regular-menu.tsx" /* webpackChunkName: "component---src-pages-regular-menu-tsx" */)
}

